/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import "./src/css/global.scss"

export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  const currentPosition = getSavedScrollPosition(location)

  if (location.hash) {
    let hash = location.hash
    hash = hash && hash.substring(1)
    if (!hash) return

    setTimeout(() => {
      const el = document.getElementById(hash)
      if (!el) return

      el.scrollIntoView()

      // If we call scrollIntoView() in here without a setTimeout
      // it won't scroll properly.
    }, 0)
  } else {
    window.scrollTo({
      left: currentPosition?.[0] || 0,
      top: currentPosition?.[1] || 0,
      behavior: "instant",
    })
  }

  return false
}
